import React from 'react';

function Header() {
  return (
      <div className="header-hd">
        <div id="header-small-links">
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href="https://db-planet.deutschebahn.com/home/startseite">DB Planet</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.dbsystel.de/">DB Systel</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://suche.app.db.de">DB Suche</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://serviceportal-shop.intranet.deutschebahn.com">Serviceportal</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.onesto.de/bahn/">Onesto</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://db.de/evi">EVI</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://db-personalportal.intranet.deutschebahn.com">Personalportal</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://ases.noncd.rz.db.de/SES/html">Atoss</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://anwenderecho.extranet.deutschebahn.com">AnwenderEcho</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://self.service.deutschebahn.com">BKU Passwort</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://dbsw.sharepoint.com/sites/ITfit">IT.fit</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://debi.app.db.de/DeBI">DeBI</a></li>
          </ul>
        </div>
        <div className="inner">
          <div id="main_logo"><a href="/">IT Support-Community</a></div>
        </div>
      </div>
  )
}

export default Header;
