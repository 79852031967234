import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";

function App() {
  return (
    <>
    <Header/>
      <Content />
    <Footer />
    </>
  )
}

export default App;
