import React from 'react';

function Footer() {
  return (
      <div id="ft">
          <div className="bd">&nbsp;</div>
          <div className="ft">
              <div className="container">
                  <span className="copyright">&copy; 2017 Deutsche Bahn AG</span>
                  <div className="sub_menu_socket sub_menu_full">
                      <ul>
                          <li>
                              <a href="https://www.dbsystel.de/dbsystel/impressum">Impressum</a>
                          </li>
                          <li></li>
                          <li>
                          </li>
                          <li>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default Footer;
