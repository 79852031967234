import React, {useEffect, useRef, useState} from 'react';


function Content() {

    const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
    const questionUrl = "https://support-solutions-gateway.extranet.deutschebahn.com/redirect/it-support-community/question/";
    const categoryUrl = "https://support-solutions-gateway.extranet.deutschebahn.com/redirect/it-support-community/category/";
    const defaultRedirectPage = "https://dbquestions.extranet.deutschebahn.com/space/IT-Support-Community";
    const dbUserActivationRedirectPage = "https://itsdapp.extranet.deutschebahn.com/welcome/selfservice/view/dbUserActivation";
    const pathname = window.location.pathname;

    const [dbQuestionsApiUrl, setDbQuestionsApiUrl] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [redirectFound, setRedirectFound] = useState( false )
    const [counter, setCounter] = useState(5);
    const [interValTimeoutInMs, setInterValTimeoutInMs] = useState(1000);
    const [dbUserActivationRedirect, setDbUserActivationRedirect] = useState(false);

    const redirectInterval = useRef();
    const isRedirectActive = (getUrlParam('redirect') !== 'false');

    function displayNoRedirectContentFound() {
        setRedirectFound( false);
        //_paq.push(['trackEvent', 'not_found', 'DBQuestions_Redirect'])
    }

    function displayRedirectContentFound( redirectUrlParam ) {
        setRedirectFound( true);
        setRedirectUrl(redirectUrlParam);
        setCounter(-1);
        //_paq.push(['trackEvent', 'found', 'DBQuestions_Redirect'])
    }

    function displayDbUserActivationRedirect() {
        setDbUserActivationRedirect( true);
        setRedirectUrl(dbUserActivationRedirectPage);
        //_paq.push(['trackEvent', 'found', 'DBQuestions_Redirect'])
    }

    function redirect(redirectUrl, redirectDirect ) {

        if(redirectDirect === true){
            window.location.assign(redirectUrl);
        }else{
            redirectInterval.current = setInterval( () =>{
                setCounter( prevCounter => {

                    if( prevCounter <= 0){
                        clearInterval(redirectInterval.current)
                        if (isRedirectActive === true) {
                            window.location.assign(redirectUrl);
                        }
                    }
                    return prevCounter-1
                })
            }, interValTimeoutInMs);
        }
    }


    function getUrlParam(sParam) {
        let sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    }

    function redirectLogic(){
        if (redirectInterval.current) clearInterval(redirectInterval.current);

        if (pathname === "/") {
            displayRedirectContentFound(defaultRedirectPage)
            redirect(defaultRedirectPage)
        }else if( pathname === "/pages/db-user-self-services"){
            displayDbUserActivationRedirect()
            redirect(dbUserActivationRedirectPage)
        }else if ( pathname.startsWith("/questions/")) {
            setDbQuestionsApiUrl( questionUrl + pathname.substring(11))
        } else if (pathname.startsWith("/categories/")) {
            setDbQuestionsApiUrl( categoryUrl + pathname.substring(12));
        }
    }

    useEffect(() => {
        redirectLogic();
    },[]);

    useEffect(() => {

        const requestOptions = {
            method: 'GET',
            headers: {"subscription-key": subscriptionKey },
        };

        if( dbQuestionsApiUrl ) {
            fetch(dbQuestionsApiUrl, requestOptions)
                .then((response) => {
                    if (response.status == 200) {
                        return response.text();
                    } else {
                        throw new Error(response.statusText)
                    }
                })
                .then((responseText) => {
                    displayRedirectContentFound(responseText)
                    redirect(responseText, true);
                })
                .catch((error) => {
                    console.log('error', error)
                    displayNoRedirectContentFound();
                });
        }

    }, [dbQuestionsApiUrl])


    return (
        <div className="redirect-mb4">
            <div id="bd2" className="yui-t7">
                <div id="yui-main">
                    <div className="yui-b">
                        <div className="ipl_grid">
                            <div id="row_298297" className="ipl_row custom_ipl_row">
                                <div className="ipl_content yui-g ">
                                    <div className="ipl_cell">
                                        <div id="widget_1001496" className="ipl_widget html_content_widget
                                    ipl_size_100 large">
                                            <div className="module" id="widget_1001496_module">
                                                <div className="bd">
                                                    <div className="b">
                                                        <div className="hd">
                                                            <div className="t">&nbsp;</div>
                                                            <div className="b redirect-mt2">
                                                                <h2>Wir sind umgezogen</h2>
                                                            </div>
                                                        </div>
                                                        {redirectFound === false && dbUserActivationRedirect === false &&
                                                            <div id="jsRedirectNotFound">
                                                                <p className="redirect-text
                                                        redirect-mt1">Leider ist der
                                                                    von Ihnen gewünschte Inhalt
                                                                    veraltet und wurde daher
                                                                    gelöscht.<br/><br/> Auf der
                                                                    neuen IT Support-Community
                                                                    können Sie nach weiteren
                                                                    Inhalten suchen oder Ihre
                                                                    Frage stellen:</p>

                                                                <p><a className="redirect-text
                                                            redirect-link"
                                                                      href="https://dbquestions.extranet.deutschebahn.com/">https://dbquestions.extranet.deutschebahn.com/</a>
                                                                </p>
                                                            </div>
                                                        }
                                                        {redirectFound === true &&
                                                            <div id="jsRedirectFound">
                                                                <p className="redirect-text
                                                        redirect-mt1">Keine Sorge,
                                                                    wir leiten Sie in <span id="redirectTimer"
                                                                                             className="redirect-text">{counter}</span> Sekunden
                                                                    automatisch auf die
                                                                    neue IT-Support-Community.</p>
                                                                <p id="redirectUrlWrapper" className="redirect-text
                                                        redirect-mt1">Sollte
                                                                    die automatische
                                                                    Weiterleitung nicht
                                                                    funktionieren, klicken Sie bitte
                                                                    auf folgenden Link:</p>
                                                                <p id="redirectUrl" className="redirect-mt1"><a
                                                                    className="redirect-text redirect-link"
                                                                    href={redirectUrl}>{redirectUrl}</a>
                                                                </p>
                                                            </div>
                                                        }

                                                        {dbUserActivationRedirect === true &&
                                                            <div id="jsRedirectFound">
                                                                <p className="redirect-text
                                                        redirect-mt1">Die DB User Aktivierung ist in die IT ServiceDesk App umgezogen.<br /><br />Keine Sorge,
                                                                    wir leiten Sie in <span id="redirectTimer"
                                                                                             className="redirect-text">{counter}</span> Sekunden
                                                                    automatisch auf die neue DB User Aktivierung.</p>
                                                                <p id="redirectUrlWrapper" className="redirect-text
                                                        redirect-mt1">Sollte
                                                                    die automatische
                                                                    Weiterleitung nicht
                                                                    funktionieren, klicken Sie bitte
                                                                    auf folgenden Link:</p>
                                                                <p id="redirectUrl" className="redirect-mt1"><a
                                                                    className="redirect-text redirect-link"
                                                                    href={redirectUrl}>{redirectUrl}</a>
                                                                </p>
                                                            </div>
                                                        }


                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;
